/* globals fbq dataLayer window */

import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import md5 from 'md5';
import Cleave from 'cleave.js/react';
import { Button, Col, Modal, Row } from 'react-materialize';
import _ from 'lodash';

import Raven from 'raven-js';
import Pricing from './components/Pricing';
import {
  apiPost,
  hasAccessToken,
  loadCompatibleDates,
  storeAccessToken,
} from '../brainApi';
import {
  setPageTitle,
  shouldUseFacebookPixel,
  tracRudderStackkEvent,
} from '../helper';
import ErrorModal from './components/ErrorModal';
import Select from './components/Select';
import { getVehicleType } from '../vehicleHelper';

class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      error: false,
      cellPhone: '',
      selectedHowDidHear: '',
      customerExists: false,
      inputValidationMessage: null,
      fleetAccountName: null,
      isGuest: false,
      isInvalidPasswordModalOpen: false,
    };
  }

  componentWillMount() {
    setPageTitle('Create Account');
  }

  componentDidMount() {
    // skip past this screen if we have an account saved to the database already
    const {
      account,
      cart,
      dispatch,
      pricing,
      location,
      homeAddress,
      z3pConfiguration,
    } = this.props;
    const { is_skipping_vehicle } = z3pConfiguration;
    if (account && account.customer_id) {
      if (cart && cart.length === 0) {
        browserHistory.push('/pricing/save');
        return;
      }

      // If the user is logged in, we can skip the "Account Creation" page.
      if (hasAccessToken()) {
        browserHistory.push('/pricing/schedule');
      }
    }

    if (_.isEmpty(location) && _.isEmpty(homeAddress)) {
      browserHistory.push('/');
      return;
    }

    /*if (!is_skipping_vehicle && _.isEmpty(pricing)) {
      browserHistory.push('/pricing/vehicle-lookup');
      return;
    }*/

    // If this is for a workplace, we can pre-load the schedule of available dates.
    // If it's not a workplace, we don't yet have the home address saved in our database,
    // so we're not able to pre-load the schedule of available dates.
    if (!homeAddress.addressNameSelected) {
      loadCompatibleDates(location.clientLocationId, cart).then((dates) => {
        dispatch({
          type: 'SET_COMPATIBLE_DATES',
          compatibleDates: dates,
        });
      });
    }

    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  onCellPhoneChange = (e) => {
    this.setState({ cellPhone: e.target.rawValue });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const {
      pricing,
      dispatch,
      referrer,
      cart,
      location,
      homeAddress,
      z3pConfiguration,
    } = this.props;
    const {
      cellPhone,
      selectedHowDidHear,
      fleetAccountName,
      isGuest,
    } = this.state;
    const { z3p_client_name: z3pEnv, is_skipping_vehicle } = z3pConfiguration;

    const { clientLocationId } = location;

    const firstName = this.firstName.value;
    const lastName = this.lastName.value;
    const email = this.email.value;
    const password = this.password.value;
    let stackEventName = 'Guest_User_Info';
    const qualifiedPhone = `+1${cellPhone}`;

    if (!isGuest && password.length < 6) {
      const inputValidationMessage =
        'Please use a password 6 characters or longer.';
      this.setState({ inputValidationMessage });
      return;
    }

    this.setState({ inputValidationMessage: null });

    const customer = {
      first_name: firstName,
      last_name: lastName,
      email,
      phone: qualifiedPhone,
      client_location_id: clientLocationId,
      how_did_hear: selectedHowDidHear,
      fleet_account_name: fleetAccountName,
      is_fleet_user: !!fleetAccountName,
    };
    if (!isGuest) {
      customer.password = md5(password);
      stackEventName = 'SimpleSchedule_New_Account';
    }

    const payload = {
      customer,
      pricing,
    };
    
    tracRudderStackkEvent(stackEventName, {
      proname: z3pConfiguration.z3p_client_name,
      first_name: customer.first_name,
      last_name: customer.last_name,
      email: customer.email,
      mobile: customer.phone,
    });

    this.setState({ processing: true });

    apiPost('/booking/customer-signup', payload).then((response) => {
      if (response.status === 409) {
        this.setState({ customerExists: true });
        this.setState({ processing: false });
        return;
      }
      if (response.status !== 200) {
        response.text().then(() => {
          const inputValidationMessage =
            'Sorry, something went wrong. Please try again.';
          this.setState({ inputValidationMessage });
          this.setState({ processing: false });
        });
        return;
      }

      response.json().then((json) => {
        const analyticsEvent = {
          event: 'Account Created',
          event_category: 'Account',
          event_label: 'Account Created',
          userId: json.customer_id,
          email: customer.email,
          firstName: customer.first_name,
          lastName: customer.last_name,
          phone: customer.phone,
          location: customer.location,
          vehicle: payload.pricing,
          referring_code: referrer.code,
          referring_source: referrer.source,
          fleet_account_name: customer.fleetAccountName,
        };

        // Push to dataLayer to be picked up by GTM
        if (typeof dataLayer !== 'undefined') {
          dataLayer.push(analyticsEvent);
        }

        // Add gtag event to be picked up by Google Analytics
        if (window && window.gtag && window.location) {
          window.gtag('event', 'Account Created', analyticsEvent);
        }

        if (shouldUseFacebookPixel(z3pEnv)) {
          // Tell Facebook Pixel about this new customer
          fbq('track', 'CompleteRegistration');
        }

        storeAccessToken(json.token);

        if (is_skipping_vehicle) {
          dispatch({ type: 'SET_VEHICLES', vehicles: [] });
        } else {
          dispatch({ type: 'SET_VEHICLES', vehicles: json.vehicles });
          // If a duplicate vehicle was found and replaced, select the updated vehicle
          if (json.vehicle_id !== pricing.vehicle_id) {
            const selectedVehicle = json.vehicles.find(
              (v) => v.vehicle_id === json.vehicle_id,
            );
            if (selectedVehicle) {
              const vehicleType = getVehicleType(selectedVehicle);
              dispatch({ type: 'SELECT_VEHICLE', vehicle: selectedVehicle });
              dispatch({
                type: 'SELECT_VEHICLE_TYPE',
                vehicleType,
              });
            }
          }
        }

        customer.customer_id = json.customer_id;
        customer.default_client_location_id = clientLocationId;

        // If customer record was previously created, replace with data saved in DB
        if (json.existing_customer) {
          customer.first_name = json.existing_customer.first_name;
          customer.last_name = json.existing_customer.last_name;
          customer.phone = json.existing_customer.phone;
          customer.fleet_account_name =
            json.existing_customer.fleet_account_name;
        }
        dispatch({ type: 'SET_ACCOUNT', account: customer });

        const page = !cart || cart.length === 0 ? 'save' : 'begin';

        // To support guest checkout, need to confirm that one of the following is true before creating an address:
        // If we have address details but no ID saved, create an address
        // If we have address details and an ID saved, only create an address if the customer IDs do not match (old address was associated with different email)
       
        if (
          homeAddress &&
          !_.isEmpty(homeAddress.address) &&
          (!homeAddress.addressIDSelected ||
            json.customer_id !== homeAddress.address.customer_id)
        ) {
          const payload = {
            ...homeAddress.address,
            is_deleted: false,
            is_primary: false,
          };
          // Don't send customer address ID in payload since we're creating a new one
          delete payload.customer_address_id;
          // If user is not logged in, use customer ID from json response
          if (!json.token) {
            payload.customer_id = json.customer_id;
          }
          apiPost('/customer/address', payload)
            .then((r) => {
              if (!r.ok) {
                throw Error(r.statusText);
              }
              return r.json();
            })
            .then((j) => {
              if (homeAddress.addressNameSelected) {
                dispatch({
                  type: 'SET_HOME_ADDRESS',
                  ...homeAddress,
                  address: j,
                  addressIDSelected: j.id,
                });
              }
              this.setState({ processing: false });
              if (json.invalid_password) {
                this.setState({ isInvalidPasswordModalOpen: true });
              } else {
                browserHistory.push(`/pricing/${page}`);
              }
            })
            .catch((e) => {
              Raven.captureException(e);
              this.setState({ error: true });
              this.setState({ processing: false });
            });
        } else {
          this.setState({ processing: false });
          if (json.invalid_password) {
            this.setState({ isInvalidPasswordModalOpen: true });
          } else {
            browserHistory.push(`/pricing/${page}`);
          }
        }
      });
    });
  };

  handleHowDidHearChange = (event) => {
    this.setState({ selectedHowDidHear: event.target.value });
  };

  render() {
    const {
      processing,
      selectedHowDidHear,
      customerExists,
      inputValidationMessage,
      error,
      isGuest,
      isInvalidPasswordModalOpen,
    } = this.state;
    const { z3pConfiguration } = this.props;
    const {
      customer_facing_name: serviceProviderName,
      z3p_client_name: z3pEnv,
      show_fleet_in_booking_app: showFleetField,
      is_how_did_hear_question_enabled: isHowDidHearQuestionEnabled,
      how_did_hear_options: howDidHearOptions,
      is_guest_checkout_enabled: guestCheckout,
    } = z3pConfiguration;

    let submitClass = 'btn-large header';
    if (processing) {
      submitClass += ' disabled ';
    }
    if (customerExists) {
      submitClass += ' disabled ';
    }

    return (
      <Pricing
        currentStep="Account"
        h1={`Create a ${serviceProviderName} Account`}
        h2={guestCheckout ? '' : 'Almost Finished...'}
        backText="Back To Services"
      >
        <Col m={12}>
          <form onSubmit={this.handleSubmit}>
            <Modal
              header="Incorrect Password"
              open={isInvalidPasswordModalOpen}
              actions={[
                <Button
                  onClick={() =>
                    this.setState({ isInvalidPasswordModalOpen: false })
                  }
                >
                  Close
                </Button>,
              ]}
            >
              <p>
                Either the username or password you entered is incorrect. Please
                try again, or check your email to reset your password.
              </p>
            </Modal>
            <Row style={{ marginBottom: 0 }}>
              {guestCheckout && (
                <>
                  <h5>Enter Contact Info</h5>
                  <p style={{ fontSize: '13px' }}>
                    Your information will only be used for day of service
                    communication and logistics
                  </p>
                </>
              )}
              <div className="input-field col m6 s12">
                <input
                  ref={(i) => (this.firstName = i)}
                  id="first_name"
                  name="first_name"
                  type="text"
                  className="validate"
                  required="required"
                  pattern=".*[^ ].*"
                />
                <label htmlFor="first_name">First Name *</label>
              </div>
              <div className="input-field col m6 s12">
                <input
                  ref={(i) => (this.lastName = i)}
                  id="last_name"
                  name="last_name"
                  type="text"
                  className="validate"
                  required="required"
                  pattern=".*[^ ].*"
                />
                <label htmlFor="last_name">Last Name *</label>
              </div>
              <div className="input-field col m6 s12">
                <input
                  ref={(i) => (this.email = i)}
                  id="email"
                  name="email"
                  type="email"
                  className="validate"
                  required="required"
                  onChange={() =>
                    this.setState({ customerExists: false, processing: false })
                  }
                />
                <label htmlFor="email">Email *</label>
                {customerExists && (
                  <Col
                    s={12}
                    className="red-splash"
                    style={{ padding: '1rem' }}
                  >
                    This email is already associated with an account.
                    <br />
                    Please&nbsp;
                    <a
                      className="waves-effect"
                      style={{ color: '#FFF' }}
                      href="/login?redirect=%2Fpricing%2Fschedule"
                    >
                      Log In
                    </a>
                    &nbsp;or&nbsp;
                    <a
                      className="waves-effect"
                      style={{ color: '#FFF' }}
                      href="/forgot-password"
                    >
                      Reset Password
                    </a>
                  </Col>
                )}
              </div>
              <div className="input-field col m6 s12">
                <Cleave
                  id="cellPhone"
                  name="cellPhone"
                  required="required"
                  options={{
                    blocks: [3, 3, 4],
                    delimiter: '-',
                    numericOnly: true,
                  }}
                  onChange={this.onCellPhoneChange}
                />
                <label htmlFor="cellPhone">Mobile Phone # *</label>
              </div>
              {guestCheckout && (
                <Col
                  s={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    padding: '0 0rem',
                    marginTop: '17px',
                  }}
                >
                  <button
                    disabled={processing}
                    className={`btn ${submitClass} marginBottom0`}
                    type="submit"
                    name="action"
                    onClick={() => {
                      this.setState({ isGuest: true });
                    }}
                  >
                    {processing && isGuest ? 'Loading...' : 'Continue as guest'}
                  </button>
                </Col>
              )}
            </Row>
            <Row>
              {guestCheckout && (
                <>
                  <h3>Create an Account</h3>
                  <h5>Save your info for next time.</h5>
                  <p style={{ fontSize: '13px' }}>
                    Optional: add a password to reschedule, see past services,
                    view online service reports and more
                  </p>
                </>
              )}
              <div style={{ display: guestCheckout ? 'flex' : 'inline' }}>
                <div
                  className="input-field col s12 m6"
                  style={{ marginLeft: guestCheckout ? 0 : 'auto' }}
                >
                  <input
                    ref={(i) => (this.password = i)}
                    id="password"
                    name="password"
                    type="password"
                    className="validate"
                    required={!guestCheckout}
                  />
                  <label htmlFor="password">
                    Create Password{guestCheckout ? '' : ' *'}
                  </label>
                </div>
              </div>
              {showFleetField && (
                <div className="input-field col m6 s12">
                  <input
                    ref={(i) => (this.fleetAccountName = i)}
                    id="fleetAccountName"
                    name="fleetAccountName"
                    type="text"
                    className="validate"
                    onChange={(e) =>
                      this.setState({ fleetAccountName: e.target.value })
                    }
                  />
                  <label htmlFor="fleetAccountName">
                    Fleet Account Name (Optional)
                  </label>
                </div>
              )}

              {isHowDidHearQuestionEnabled && howDidHearOptions?.length > 0 && (
                <Select
                  s={12}
                  m={6}
                  onChange={this.handleHowDidHearChange}
                  id="howDidHear"
                  name="howDidHear"
                  value={selectedHowDidHear}
                >
                  <option value="" disabled>
                    How did you hear about us?
                  </option>
                  {howDidHearOptions?.map((option, index) => {
                    return (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    );
                  })}
                </Select>
              )}

              <Col
                s={12}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  marginTop: '17px',
                  paddingLeft: '0px',
                }}
              >
                <button
                  type="submit"
                  disabled={processing}
                  className={`btn ${submitClass}`}
                  name="action"
                  onClick={() => {
                    this.setState({ isGuest: false });
                  }}
                >
                  {processing && !isGuest
                    ? 'Loading...'
                    : 'Create account & continue'}
                </button>
              </Col>
              {!!inputValidationMessage && inputValidationMessage.length > 0 && (
                <Col s={6} className="red-splash" style={{ padding: '1rem' }}>
                  {inputValidationMessage}
                </Col>
              )}
              {z3pEnv === 'aaa-norcal' && (
                <Col
                  s={12}
                  style={{ margin: '40px 0 -25px -10px', fontSize: '.9rem' }}
                >
                  *The contact information you provide will be used for purposes
                  related to Membership services, such as appointment reminders.
                </Col>
              )}
            </Row>
          </form>
          <ErrorModal
            error={error}
            onClose={() => browserHistory.push(`/account`)}
            errorText="Sorry, there was a problem creating your address. Please add a new
          address."
          />
        </Col>
      </Pricing>
    );
  }
}

function mapStateToProps(state) {
  return {
    pricing: state.ui.pricing,
    cart: state.ui.cart,
    account: state.ui.account,
    referrer: state.ui.referrer,
    location: state.ui.customerLocation,
    homeAddress: state.ui.homeAddress,
    z3pConfiguration: state.ui.z3pConfiguration,
  };
}

export default connect(mapStateToProps, null)(Account);
